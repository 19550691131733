<template>
  <div class="carousel">
    <div class="carousel__items">
      <img :src="currentImage" alt="image slider" width="100%" />
    </div>

    <a v-if="images.length > 1" :class="['carousel__action carousel__prev', { disabled: index === 0 }]" @click="go(-1)">
      <toko-circle-prev-icon />
    </a>
    <a
      v-if="images.length > 1"
      :class="['carousel__action carousel__next', { disabled: index === images.length - 1 }]"
      @click="go()"
    >
      <toko-circle-next-icon />
    </a>
  </div>
</template>

<script>
import TokoCirclePrevIcon from '@/ui/toko-icons/TokoCirclePrev'
import TokoCircleNextIcon from '@/ui/toko-icons/TokoCircleNext'

export default {
  name: 'ImageSlider',

  props: {
    images: {
      type: Array,
      required: true
    },

    currentIndex: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      index: 0
    }
  },

  components: {
    TokoCirclePrevIcon,
    TokoCircleNextIcon
  },

  computed: {
    currentImage() {
      return this.images[Math.abs(this.index) % this.images.length]
    }
  },

  methods: {
    go(step = 1) {
      this.index += step
    }
  },

  created() {
    this.index = this.currentIndex
  }
}
</script>
